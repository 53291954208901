<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title>{{
            $t('page.contenttype_entries.edit_entry_title', {
              type: getContenttypeName,
            })
          }}</v-card-title>
          <v-divider />
          <div class="pa-5" v-if="contenttype_fields == 0">{{ $t('page.contenttype_entries.no_fields') }}</div>
          <contententry-input
            v-for="(field, index) in contenttype_fields"
            v-model="contenttype_fields[index]"
            :key="index"
            mode="edit"
          />

          <v-text-field
            v-if="contenttype.is_sortable"
            v-model="priority_index"
            type="number"
            :label="$t('page.contenttype_entries.sorting')"
            :hint="$t('common.choose_between', { start: 1, end: contenttype.last_priority_index })"
            :error-messages="$t(errors['priority_index'])"
            hide-details="auto"
            outlined
            class="pa-5"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-text>
            <v-btn
              @click="editContenttypeEntry"
              :disabled="loading"
              :loading="loading"
              color="accent"
              type="submit"
              block
              small
              class="mb-3"
            >
              <v-icon class="mr-1">{{ mdiContentSaveOutline }}</v-icon>
              {{ $t('common.save') }}
            </v-btn>
            <v-btn small block outlined :disabled="loading" @click="$router.push('/contenttype/entries')">
              <v-icon class="mr-1" small>{{ mdiArrowULeftTop }}</v-icon>
              {{ $t('common.back') }}
            </v-btn>
          </v-card-text>

          <v-divider class="my-1"></v-divider>

          <v-card-text>
            <delete-button :deleteOnClick="deleteOnClick" :loading="loading" :disabled="loading" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mdiArrowULeftTop, mdiContentSaveOutline } from '@mdi/js'
import Vue from 'vue'
import ContententryInput from './ContententryInput.vue'
import DeleteButton from '@/components/DeleteButton.vue'
export default {
  components: {
    ContententryInput,
    DeleteButton,
  },
  beforeMount() {
    this.langs = this.$store.getters['i18n/langs']

    this.$http('contenttypes/' + this.$route.params.api_identifier + '/entries/' + this.$route.params.id).then(
      content_res => {
        this.contenttype = content_res.data.contenttype
        this.priority_index = content_res.data.priority_index

        //nadat de content is opgehaald, kunnen de velden worden opgehaald waarna de models gevuld worden met de beschikbare content
        this.$http('contenttypes/' + this.$route.params.api_identifier + '/fields?sort=order_index&direction=asc').then(
          fields_res => {
            //format fields
            fields_res.data.data.forEach(field => {
              if (field.is_translatable) {
                field.model = {}
                this.langs.forEach(lang => {
                  field.model[lang.key] = {
                    model: '',
                    error: '',
                  }
                  if (content_res.data.content[field.api_identifier]) {
                    field.model[lang.key].model = content_res.data.content[field.api_identifier][lang.key]
                  }
                })
              } else if (field.type == 'boolean') {
                field.model = content_res.data.content[field.api_identifier] == 'true'
                field.error = ''
              } else {
                field.model = content_res.data.content[field.api_identifier]
                field.error = ''
              }
            })
            this.contenttype_fields = fields_res.data.data
          },
        )
      },
    )
  },
  data() {
    return {
      title: '',
      contenttype: {},
      errors: {},
      contenttype_fields: [],
      priority_index: '',
      loading: false,
      //icons
      mdiArrowULeftTop,
      mdiContentSaveOutline,
    }
  },
  methods: {
    editContenttypeEntry() {
      var request_data = new FormData()
      this.contenttype_fields.forEach(field => {
        if (field.is_translatable) {
          for (var prop in field.model) {
            request_data.append(field.api_identifier + '[' + prop + ']', field.model[prop].model)
          }
        } else if (field.type == 'file') {
          let file = document.getElementById(field.api_identifier).files[0]
          if (file) {
            for (let i = 0; i < document.getElementById(field.api_identifier).files.length; i++) {
              request_data.append(
                field.api_identifier + '-' + i,
                document.getElementById(field.api_identifier).files[i],
              )
            }
          } else if (field.model == null || field.model.length == 0) {
            request_data.append(field.api_identifier, 'DELETED')
          } else {
            request_data.append(field.api_identifier, 'UNCHANGED')
          }
        } else {
          if (field.model !== '') {
            request_data.append(field.api_identifier, field.model)
          }
        }
      })

      if (this.contenttype.is_sortable) {
        request_data['priority_index'] = this.priority_index
      }

      this.$http
        .post('contenttypes/' + this.$route.params.api_identifier + '/entries/' + this.$route.params.id, request_data)
        .then(res => {
          //update all files
          this.contenttype_fields.forEach(field => {
            if (field.type == 'file') {
              if (res.data.content[field.api_identifier]) {
                field.model = res.data.content[field.api_identifier]
              } else {
                field.model = {}
              }
            }
          })
          this.errors = {}
          this.$store.commit('notification/notify', {
            text: 'notification.saved',
          })
        })
        .catch(error => {
          if (!error.response) {
            this.$store.commit('notification/notify', {
              text: 'notification.cms_entries_payload_to_big',
            })
          } else if (typeof error == 'object' && typeof error.response.data.error == 'object') {
            this.errors = error.response.data.error

            this.contenttype_fields.forEach(field => {
              if (field.is_translatable) {
                this.langs.forEach(lang => {
                  if (this.errors[field.api_identifier] && this.errors[field.api_identifier][lang.key]) {
                    field.model[lang.key].error = this.errors[field.api_identifier][lang.key]
                  } else {
                    field.model[lang.key].error = ''
                  }
                })
              } else {
                if (this.errors[field.api_identifier]) {
                  field.error = this.errors[field.api_identifier]
                } else {
                  field.error = ''
                }
              }
            })

            if (this.errors.priority_index) {
              this.priority_index.error = this.errors['priority_index']
            }

            if (!this.errors.hasOwnProperty('translations')) {
              this.errors.translations = {}
            }
            this.langs.forEach(lang => {
              if (!this.errors.translations.hasOwnProperty(lang.key)) {
                this.errors.translations[lang.key] = {
                  name: '',
                }
              }
            })
          }
        })
    },
    deleteOnClick() {
      this.loading = true
      this.$http
        .delete('contenttypes/' + this.contenttype.api_identifier + '/entries/' + this.$route.params.id)
        .then(() => {
          this.$store.commit('notification/notify', {
            text: 'notification.deleted_success',
            color: 'success',
          })
          this.$router.push('/contenttype/entries')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  computed: {
    getContenttypeName() {
      if (Object.keys(this.contenttype).length > 0) {
        return Vue.filter('translated')(this.contenttype.contenttype_translations)
      }
      return ''
    },
  },
  watch: {},
}
</script>
